<template>
  <div style="overflow:auto;">
    <BatchIpt v-if="!viewOnly" @onSubmit="onBatchIpt"></BatchIpt>
    <table class="table">
      <tr style="height:50px;background:#F7F8FA;">
        <td style="min-width: 80px;">材质</td>
        <td style="min-width: 80px;">颜色</td>
        <template v-if="table.dynaColumns.length>0">
          <td v-for="(column,idx) in table.dynaColumns" :key="idx" style="min-width: 80px;">
            {{column.title}}
            <!-- <i v-if="!viewOnly" class="iconfont icon-zu9 delcol-btn" @click="delCol(column)"></i> -->
          </td>
        </template>
        <td style="width:140px;">条码</td>
        <td style="width:140px;">型号</td>
        <template v-if="viewOnly">
          <td style="width: 150px;">订货数量</td>
          <td style="width: 150px;">阶梯报价(单价)</td>
        </template>
        <td v-else style="min-width:460px;">
          阶梯报价
          <el-popover placement="top-start" trigger="hover" width="400">
            <div>
              <p>1、填写起订数量和单价，如起订数量1~15，商品单价￥998.00</p>
              <p>2、最后一行的起订数量右范围，可不填。无论是否填写，默认左范围及以上。如最后起订数量 51~60或51~空，默认为51及以上</p>
            </div>
            <i
              class="el-icon-warning"
              style="color:rgba(1,111,255,1);font-size: 18px;"
              slot="reference"
            ></i>
          </el-popover>
        </td>
        <td v-if="!viewOnly" style="width: 100px;">操作</td>
      </tr>
      <tr style="height:40px;" v-for="(row,rowIdx) in table.rows" :key="rowIdx">
        <template v-for="(col,colIdx) in row.cols">
          <td class="td" v-if="!col.group" :key="colIdx" :rowspan="col.rowspan">{{col.val}}</td>
        </template>
        <td class="td" :class="{'td-disable':row.raw.stat==2}">
          <el-input
            v-if="!viewOnly"
            size="small"
            v-model="row.raw.code"
            placeholder="请输入条码" 
            :disabled="row.raw.stat==2"
            class="sn"
          ></el-input>
          <span v-else>{{row.raw.code}}</span>
        </td>
        <td class="td" v-bind:class="{ 'not-null': !row.raw.module_no,'td-disable':row.raw.stat==2 }">
          <el-input
            v-if="!viewOnly"
            size="small"
            v-model="row.raw.module_no"
            placeholder="请输入型号" 
            :disabled="row.raw.stat==2"
            class="sn"
            ref="module_no"
          ></el-input>
          <span v-else>{{row.raw.module_no}}</span>
        </td>
        <template v-if="viewOnly">
          <td colspan="2">
            <template v-if="row.raw.prices">
              <div
                v-for="(price,pidx) in sortBy(row.raw.prices,'start')"
                :key="pidx"
                class="flex price-view"
              >
                <div class="flex-1">{{price.start}}{{price.end ? '~'+price.end:'以上'}}</div>
                <div class="flex-1" style="text-align:right;"><span style="margin-right: 10px;">￥{{price.price | priceFilt}}</span></div>
              </div>
            </template>
          </td>
        </template>
        <td v-else class="td" :class="{'td-disable':row.raw.stat==2}">
          <Prices v-model="row.raw.prices" :ref="'price_'+rowIdx" :disabled="row.raw.stat==2"></Prices>
        </td>
        <td v-if="!viewOnly" class="td">
          <template>
            <el-button v-if="row.raw.stat==2" type="text" @click="enable(rowIdx)">启用</el-button>
            <el-button v-if="row.raw.stat==1" type="text" @click="disable(rowIdx)">禁用</el-button>
          </template>
          <!-- <el-button type="text" @click="del(rowIdx)">删除</el-button> -->
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import remove from "lodash/remove";
import Prices from "./Prices";
import BatchIpt from "./BatchIpt";
export default {
  components: { Prices,BatchIpt },
  model: {
    prop: "skus",
    event: "change"
  },
  props: ["skus", "selected", "materials", "colors","inCatAttrs","module_no", "viewOnly"],
  data() {
    return {
      sortBy: sortBy,
      catAttrs:null,
      newSkus: [],
      table: {
        dynaColumns: [],
        rows: []
      }
    };
  },
  watch: {
    selected: {
      handler: function(newVal, oldVal) {
        if(this.newSkus.length>0){
          let newAttrStr = newVal.attrs.reduce((pre, cur) => {
            pre.push(cur.id);
            return pre;
          }, []).sort().join();
          let oldAttrStr = (this.newSkus[0].attrs||[]).reduce((pre, cur) => {
            pre.push(cur.id);
            return pre;
          }, []).sort().join();
          if(newAttrStr!=oldAttrStr) this.newSkus=[];
        }
        this.genSkus(this.newSkus, newVal);
        if (this.newSkus.length > 0) this.rendTable();
        // this.$emit("change", newVal);
      },
      deep: true
    },
    newSkus: {
      handler: function(newVal, oldVal) {
        this.$emit("change", newVal);
      },
      deep: true
    }
  },
  computed: {},
  async created() {
    if(this.inCatAttrs) this.catAttrs = sortBy(this.inCatAttrs,["name"]);
    this.cleanAttrs();
    if (this.newSkus && this.newSkus.length > 0) this.rendTable();
  },
  methods: {
    /**
     * 去除已删除的sku属性和对应不上值的
     */
    cleanAttrs(){
      if(this.skus && this.skus.length>0) this.newSkus = this.skus.reduce((pre,cur)=>{
        if(cur.attrs && cur.attrs.length>0){
          if(this.catAttrs && this.catAttrs.length>0){
            let _attrs=[];
            for(let catAttr of this.catAttrs){
              let _attr = {
                id:catAttr.id,
                name:catAttr.name
              };
              let exist = cur.attrs.find(at=>{
                return at.id==catAttr.id;
              });
              if(exist){
                if(catAttr.items && !exist.valueId){
                  let existItem = catAttr.items.find(item=>{
                    return item.id==exist.id;
                  });
                  if(existItem) _attr.valueId=existItem.id;
                }
                Object.assign(_attr,{valueId:exist.valueId,value:exist.value});
                _attrs.push(_attr);
              }
            }
            cur.attrs = _attrs;
          }else{
            cur.attrs=[];
          }
        }
        pre.push(cur);
        return pre;
      },[]);
    },
    genSkus(items, val) {
      if (val && val.material && val.color) {
        if (val.attrs && val.attrs.length > 0) {
          let nullAttr = val.attrs.find(r => {
            return r.value == null || r.value.length == 0;
          });
          if (nullAttr) return;
        }
        let newItems = [];
        this._genSkus(newItems, items, val);
        this.newSkus = newItems;
        
      }
    },
    _genSkus(newItems, items, conds) {
      for (let _material of conds.material) {
        for (let _color of conds.color) {
          let attrs = this._dkr([], 0, conds.attrs);
          if (attrs && attrs.length > 0) {
            for (let attr of attrs) {
              this._push(newItems, items, _material, _color, attr);
            }
          } else {
            this._push(newItems, items, _material, _color);
          }
        }
      }
    },
    compareAttrs(srcAttrs,targetAttrs){
      let srcAttrStr = srcAttrs.reduce((pre, cur) => {
        pre.push({ id: cur.id,value: cur.value });
        return pre;
      }, []);
      srcAttrStr = JSON.stringify(sortBy(srcAttrStr, "id"));
      let targetAttrStr = targetAttrs.reduce((pre, cur) => {
        pre.push({ id: cur.id,value: cur.value });
        return pre;
      }, []);
      targetAttrStr = JSON.stringify(sortBy(targetAttrStr, "id"));
      return srcAttrStr == targetAttrStr;
    },
    _push(newItems, items, _material, _color, attr) {
      let exist = items.find(item => {
        let cond1 = item.material == _material;
        let cond2 = item.color == _color;
        let cond3 = true;
        if (attr) {
          cond3 = this.compareAttrs(item.attrs,attr);
        }
        return cond1 && cond2 && cond3;
      });
      if (exist) {
        newItems.push(exist);
      } else {
        newItems.push({
          material: _material,
          color: _color,
          attrs: attr,
          stat:1,
          // module_no:this.module_no,
          module_no:null,
          prices:[]
        });
      }
    },
    _dkr(_items, idx, attrs) {
      let slectedAttr = attrs[idx];
      if (!slectedAttr) return _items;
      let newAttrs = [];
      slectedAttr.value.forEach(value => {
        if (_items.length > 0) {
          _items.forEach(item => {
            let _specs = cloneDeep(item);
            let newAttr = [
              { id: slectedAttr.id, name: slectedAttr.name, valueId: value.id,value:value.value }
            ];
            newAttr.push(..._specs);
            newAttrs.push(newAttr);
          });
        } else {
          newAttrs.push([
            { id: slectedAttr.id, name: slectedAttr.name, valueId: value.id,value:value.value }
          ]);
        }
      });
      idx = idx + 1;
      return this._dkr(newAttrs, idx, attrs);
    },
    rendTable() {
      let sortFields = ["material", "color"];
      let tmpSkus = cloneDeep(this.newSkus);
      if(tmpSkus.length>1 && tmpSkus[0].attrs && tmpSkus[0].attrs.length>0){
        tmpSkus.map(_sku=>{
          _sku.attrs = sortBy(_sku.attrs,["name"]);
          return _sku;
        });
        for(let idx=0;idx<tmpSkus[0].attrs.length;idx++){
          sortFields.push(`attrs[${idx}].value`);
        }
      }
      this.newSkus = sortBy(tmpSkus, sortFields);
      this.table.dynaColumns = [];
      if ( this.newSkus && this.newSkus.length ) {
        let cols=[];
        for(let newSku of this.newSkus){
          if(newSku.attrs && newSku.attrs.length>0){
            for(let _attr of newSku.attrs){
              let exist = cols.find(r=>{
                return r.key==_attr.id;
              });
              if(!exist){
                cols.push({
                  title: _attr.name,
                  key: _attr.id
                });
              }
            }
          }
        }
        if(cols.length>0) this.table.dynaColumns=this.table.dynaColumns.concat(cols);
      }
      this.table.rows = this.newSkus.reduce((pre, cur) => {
        // 如果是查看状态则移除禁用的sku
        if(this.viewOnly && cur.stat != 1) return pre;
        let row = [
          { val: this.getMaterialValue(cur.material) },
          { val: this.getColorlValue(cur.color) }
        ];
        if(this.table.dynaColumns && this.table.dynaColumns.length>0){
          for(let dynaCol of this.table.dynaColumns){
            let val = null;
            if(cur.attrs && cur.attrs.length>0){
              let exist = cur.attrs.find(r=>{
                return r.id==dynaCol.key;
              });
              if(exist) val=exist.value;
            }
            row.push({ val: val });
          }
        }
        let preRowObj = pre[pre.length - 1];
        if (preRowObj) {
          let preRow = preRowObj.cols;
          for (let i = 0; i < row.length; i++) {
            let preCol = row[i - 1];
            if (preCol && !preCol.group) continue;
            if (row[i].val == preRow[i].val) {
              let preRowColGroup = preRow[i].group;
              if (preRowColGroup) {
                preRowColGroup.rowspan++;
                row[i].group = preRowColGroup;
              } else {
                preRow[i].rowspan = 2;
                row[i].group = preRow[i];
              }
            }
          }
        }
        pre.push({ cols: row, raw: cur });
        return pre;
      }, []);
    },
    getDynaTdValue(attrs, id) {
      let exist = attrs.find(r => {
        return r.id == id;
      });
      return exist ? exist.value : "";
    },
    getMaterialValue(id) {
      if (!this.materials) return "";
      let exist = this.materials.find(r => {
        return r.id == id;
      });
      return exist ? exist.name : "";
    },
    getColorlValue(id) {
      if (!this.colors) return "";
      let exist = this.colors.find(r => {
        return r.id == id;
      });
      return exist ? exist.name : "";
    },
    /* del(idx){
      this.newSkus.splice(idx, 1);
      this.rendTable();
    }, */
    /* delCol(col){
      if(this.newSkus && this.newSkus.length>0){
        for(let newSku of this.newSkus){
          if(newSku.attrs && newSku.attrs.length>0){
            remove(newSku.attrs,r=>{
              return r.id==col.key;
            });
          }
        }
        this.rendTable();
      }
    }, */
    onBatchIpt(obj){
      if (this.table.rows && this.table.rows.length > 0) {
        for(let row of this.table.rows){
          if(obj.module_no && !row.raw.module_no) row.raw.module_no=obj.module_no;
          if(obj.code && !row.raw.code) row.raw.code=obj.code;
        }
      }
    },
    async valid() {
      if (this.table.rows && this.table.rows.length > 0) {
        for (let i = 0; i < this.table.rows.length; i++) {
          let _curRow = this.table.rows[i].raw;
          if(_curRow.stat==2) continue;
          let duplicaRow = this.table.rows.find((row,idx)=>{
            if(idx!=i && row.raw.stat!=2){
              if(_curRow.material==row.raw.material && _curRow.color==row.raw.color){
                return this.compareAttrs(_curRow.attrs,row.raw.attrs);
              }
            }
          });
          if(duplicaRow) throw new Error("请删除规格属性重复记录！");
          let refname = "price_" + i;
          await this.$refs[refname][0].valid();
          // console.log(_curRow,i,this.$refs["module_no"]);
          if(!_curRow.module_no) throw new Error("请输入型号！");
          if(!(_curRow.prices && _curRow.prices.length>0)) throw new Error("请添加阶梯报价！");
        }
      }
    },
    enable(idx){
      this.newSkus[idx].stat=1;
    },
    disable(idx){
      this.newSkus[idx].stat=2;
    }
    // onChange() {
    //   this.$emit("change", []);
    // }
  }
};
</script>
<style scoped>
.table {
  border: 1px solid #E6E6E6;
  width: 100%;
  min-width: 1000px;
  border-spacing: 0;
  border-collapse: collapse;
}
.table td {
  text-align: center;
  border-right: none;
  border-bottom: none;
  border: 1px solid #E6E6E6;
  color: #808080;
}
.table td.td-disable{
  background: #F5F7FA;
}
.table tr:not(:first-child) td:last-child > div > div:last-child {
  color: #ee1818;
}
.delcol-btn{
  margin-left: 10px;
  color:#5074EE;
}
.sn >>> input {
  border: none;
  background: transparent;
}
.not-null{
      background: #fdf6ec;
}
.price-view:not(:last-child) {
  border-bottom: 1px solid #ebeef5;
}
.price-view > div:not(:last-child) {
  border-right: 1px solid #ebeef5;
}
</style>