<template>
  <div>
    <div v-for="(row,_index) of prices_" :key="_index" class="flex flex-center price">
      <Price v-model="row.data" :ref="'price_'+_index" class="flex-1" :isLast="_index==prices_.length-1" :isFirst="_index==0" :disabled="disabled" @change="onPriceChange($event,_index)"></Price>
      <!-- 只能从最后一条报价开始删除,且不能删除第一条报价 -->
      <el-button v-if="_index>0 && _index==(prices_.length-1)" :disabled="disabled" type="text" icon="el-icon-delete" class="del-btn" @click="del(_index)"></el-button>
    </div>
    <el-button :disabled="disabled" type="text" icon="el-icon-circle-plus-outline" @click="addPrice">点击添加</el-button>
  </div>
</template>
<script>
import Price from "./Price";
import get from 'lodash/get';
import clone from 'lodash/clone';
import sortBy from "lodash/sortBy";
export default {
  components: {
    Price
  },
  model: {
    prop: "prices",
    event: "change"
  },
  props: ["prices","disabled"],
  data() {
    return {
      // prices_: sortBy(this.prices,'start')
      prices_: this.prices.map(r=>{return {data:r}})
    };
  },
  created() { },
  watch: {
    prices: function (newv,oldv) {
      // this.prices_=sortBy(this.prices,'start');
      this.prices_=this.prices.map(r=>{return {data:r}})
    }
  },
  methods: {
    onPriceChange(price,idx){
      this.onPricesChange();
    },
    onPricesChange(){
      let rst = this.prices_.map(r=>{
        return r.data;
      });
      console.log("onPricesChange",rst);
      this.$emit("change", rst);
    },
    async addPrice() {
      if (this.prices_) {
        try{
          await this.validChilds();
          let prePrice = get(this.prices_,`[${this.prices_.length-1}].data`);
          let newPrice = {};
          if(prePrice){
            if(!prePrice.end){
              prePrice.end=prePrice.start+1;
              this.$set(this.prices_,this.prices_.length-1,{data:clone(prePrice)});
              this.$forceUpdate();
            }
            newPrice.start = prePrice.end+1;
          }else{
            newPrice.start=1
          }
          this.prices_.push({data:newPrice});
          this.onPricesChange();
        }catch(err){
          console.log(err);
          this.$message.error(err.message);
        }
      } else {
        this.prices_ = [{data:{start:1}}];
        this.onPricesChange();
      }
      
    },
    async validChilds(){
      this.$forceUpdate();
      if (this.prices_ && this.prices_.length > 0) {
        for (let i = 0; i < this.prices_.length; i++) {
          let refname = "price_" + i;
          let up = get(this.prices_,`[${i-1}].data`);
          let down = get(this.prices_,`[${i+1}].data`);
          await this.$refs[refname][0].validForm(up,down);
          
        }
      }
    },
    async valid() {
      await this.validChilds();
      this.onPricesChange();
      console.log("Prices.valid",this.prices_);
    },
    del(_index) {
      let prePrice = get(this.prices_,`[${_index-1}].data`);
      if(prePrice && prePrice.end){
        prePrice.end=null;
        this.$set(this.prices_,_index-1,{data:clone(prePrice)});
        
      }
      this.$delete(this.prices_,_index)
      this.onPricesChange();
    }
  }
};
</script>
<style scoped>
.price {
  padding: 5px;
}
.del-btn {
  font-size: 22px;
  padding: 0 8px;
}
</style>