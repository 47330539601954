<template>
  <div v-if="ruleForm" class="prices">
    <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" :show-message="false" :disabled="disabled">
      <el-form-item prop="start" required title="起订数量">
        <el-input-number
          size="small"
          v-model="ruleForm.start"
          style="width:120px"
          @change="onValueChange" :disabled="isFirst"
        ></el-input-number>
      </el-form-item>
      <el-form-item>~</el-form-item>
      <el-form-item prop="end">
        <div v-if="isLast" style="width:120px;padding: 4px;">以上</div>
        <el-input-number
          v-else
          size="small"
          v-model="ruleForm.end"
          style="width:120px"
          @change="onValueChange"
        ></el-input-number>
      </el-form-item>
      <el-form-item prop="price" required title="单价">
        <el-input
          size="small"
          type="number"
          min="0"
          v-model="ruleForm.price" 
          @change="onValueChange" 
          style="width:100px"
          class="price"
        ></el-input>元
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import clone from 'lodash/clone';
export default {
  props: ["price", "formRef", "isLast","isFirst","disabled"],
  model: {
    prop: "price",
    event: "change"
  },
  data() {
    return {
      up:null,
      down:null,
      ruleForm: {
        start:null,
        end:null,
        price:null
      },
      rules: {
        start: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value && value > 0) {
                if(this.up && value<=this.up.end){
                    callback(new Error(`起订数量必须大于上一阶梯的终止数量!`));
                }else{
                    callback();
                }
              } else {
                callback(new Error(`起订数量必须大于0!`));
              }
            },
            trigger: "blur"
          }
        ],
        end: [
          {
            validator: (rule, value, callback) => {
              if(!this.isLast){
                  if(value){
                      if(value<this.ruleForm.start){
                          callback(new Error(`终止数量必须大于等于起订数量!`));
                      }
                      // if(this.down && value>this.down.start){
                      //     callback(new Error(`终止数量必须小于等于下一阶梯的起订数量!`));
                      // }
                  }else{
                    callback(new Error(`请设置终止数量!`));
                  }
              }
              callback();
            },
            trigger: "blur"
          }
        ],
        // bg: [{ required: true, message: '请输入项目背景', trigger: 'blur' }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }]
        // photo: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        // url: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
      }
    };
  },
  watch: {
    price:{
      handler: function(newVal, oldVal) {
        console.log("Price.watch.price",newVal, oldVal,this.price);
        if(newVal){
          this.ruleForm.start = newVal.start;
          this.ruleForm.end = newVal.end;
          this.ruleForm.price = newVal.price;
          // this.$forceUpdate();
        }

      },
      deep: true
    },
    /* up: {
      handler: function(newVal, oldVal) {console.log("watch.up",newVal, oldVal);
        if (newVal) this.ruleForm.start = newVal.end + 1;
      },
      deep: true
    }, */
    /* down: {
      handler: function(newVal, oldVal) {
        console.log("watch.down",newVal);
       
      },
      deep: true
    } */
  },
  async created() {},
  mounted() {
    // console.log(3333,this.price,JSON.stringify(this.price),);
    this.ruleForm.start = this.price.start;
    this.ruleForm.end = this.price.end;
    this.ruleForm.price = this.price.price;
    // Object.assign(this.ruleForm,this.item);
    // console.log("111",this.ruleForm);
    // console.log(this.item);
  },
  methods: {
    onValueChange(currentValue, oldValue) {
      this.$emit("change", this.ruleForm);
    },
    // onStartChange(currentValue, oldValue) {
    //   // if (this.ruleForm.end && currentValue >= this.ruleForm.end && !this.isLast) {
    //   //   this.ruleForm.end++;
    //   // }
    //   // this.$forceUpdate();
    //   this.$emit("change", this.ruleForm);
    // },
    // onEndChange(currentValue, oldValue) {
    //   // if (this.ruleForm.start > 1 && currentValue <= this.ruleForm.start) {
    //   //   this.ruleForm.start--;
    //   // }
    //   // this.$forceUpdate();
    //   this.$emit("change", this.ruleForm);
    // },
    validForm(_up,_down) {
      this.up=_up;
      this.down=_down;
      return new Promise((resolve, reject) => {
        // this.$emit('update:price', this.ruleForm);
        this.$refs.ruleForm.validate((valid, obj) => {
          if (valid) {
            resolve(true);
          } else {
            console.log(obj);
            return reject(new Error(obj[Object.keys(obj)[0]][0].message));
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.prices{
  text-align: left;
}
.prices >>> .el-form-item {
  margin-bottom: 0;
}
.prices >>> .el-form-item__content {
  line-height: initial;
}
.price >>> input {
  padding: 0;
  text-align: right;
}
</style>